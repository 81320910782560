<script setup>
import Checkbox from '@/Components/Checkbox.vue';
import GuestLayout from '@/Layouts/GuestLayout.vue';
import InputError from '@/Components/InputError.vue';
import InputLabel from '@/Components/InputLabel.vue';
import PrimaryButton from '@/Components/PrimaryButton.vue';
import SecondaryButton from '@/Components/SecondaryButton.vue';
import TextInput from '@/Components/TextInput.vue';
import { Head, Link, useForm } from '@inertiajs/vue3';
import { router } from '@inertiajs/vue3'
defineProps({
    canResetPassword: {
        type: Boolean,
    },
    status: {
        type: String,
    },
});

const form = useForm({
    email: '',
    password: '',
    remember: false,
});



const submit = () => {
    form.post(route('login'), {
        onFinish: () => form.reset('password'),
    });

   
};

const navigateToRegister = () => {
    // router.visit('/r');
    window.location.href = '/r';
};
</script>

<template>
    <GuestLayout>
        <Head title="Log in" />

        <div v-if="status" class="mb-4 font-medium text-sm text-green-600">
            {{ status }}
        </div>

        <form @submit.prevent="submit">
            <div>
                <InputLabel for="email" v-bind:value="$t('Email')" />

                <TextInput
                    id="email"
                    type="email"
                    class="mt-1 block w-full"
                    v-model="form.email"
                    required
                    autofocus
                    autocomplete="username"
                />

                <InputError class="mt-2" :message="form.errors.email" />
            </div>

            <div class="mt-4">
                <InputLabel for="password" v-bind:value="$t('Password')" />

                <TextInput
                    id="password"
                    type="password"
                    class="mt-1 block w-full"
                    v-model="form.password"
                    required
                    autocomplete="current-password"
                />

                <InputError class="mt-2" :message="form.errors.password" />
            </div>

            <div class="block mt-4">
                <label class="flex items-center">
                    <Checkbox name="remember" v-model:checked="form.remember" />
                    <span class="ml-2 text-sm text-gray-600">{{$t('Remember me')}}</span>
                </label>
            </div>

            <div class="flex flex-col sm:flex-row-reverse items-center justify-between mt-4">
    <PrimaryButton class="w-full sm:w-auto mt-4 sm:mt-0 flex justify-center items-center" :class="{ 'opacity-25': form.processing }" :disabled="form.processing">
        {{ $t('Log in') }}
    </PrimaryButton>
    <div class="flex flex-col items-center sm:items-start w-full sm:w-auto">
        <Link
            v-if="canResetPassword"
            :href="route('password.request')"
            class="underline text-sm text-gray-600 hover:text-gray-900 mb-2 sm:mb-0"
        >
            {{ $t('Forgot your password?') }}
        </Link>
        <Link
            @click="navigateToRegister"
            class="underline text-sm text-gray-600 hover:text-gray-900"
        >
            {{ $t('Register') }}
        </Link>
    </div>
</div>
        </form>
    </GuestLayout>
</template>
